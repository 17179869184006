import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Divider from "@material-ui/core/Divider";
import Text from "../../../components/Text";
import AvailableProduct from "./available-brand-products";
import AdvanceFilter from "../../../components/AdvancedFilter";
import {onlyUnique, sortFilter} from "../../../helpers/globalFunc";
import MobileFilter from "../../../components/MobileFilter";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {fetchBrand} from "../../../redux/actions/home";
import {useHistory} from "react-router-dom";

const AllProduct = (props) => {


  let {
    categories,
    slug,
    posts,
    fetchFiltered,
    filteredProduct,
    categoryProducts,
    filterInit,
    id,
    brand,
    loading,
      setData,
      data,
    setSearch,
      isSearch,
    categoryProductInit,
    catId,
    pageNumber,
    changePageNumber, submit
  } = props;

  let [sort, setSort] = useState([]);
  let [showPopup, setShowPopup] = useState(false);
  const brandList = fetchBrand()
  let vertical = "bottom";
  let horizontal = "left";

  useEffect(()=>{
    filterInit()
  },[])

  const post = posts
    ? posts.length
      ? posts.find((item) => item.post_name === slug)
      : null
    : null;
   
  const postContent = post ? post.post_content : "";

  const brandName = brand ? brand.name : "";
  const branDec = brand ? brand.description : "";
  // const brandInnerImage = brand ? brand. : "";inner_banner
  const brandInner = brand ? brand.inner_banner : "";
  const logo = brand ? brand.logo : "";
  let newArrivals = categories
    ? categories.length
      ? categories.find((item) => item.slug === "pre-book-now")
      : null
    : null;

  let newId = newArrivals ? newArrivals.term_id : null;

  let postImage = post ? post.custom_extra_banner : null;

  let newArrivalList = categoryProducts
    ? categoryProducts.length
      ? categoryProducts.filter((item) =>
          item.category_ids.some((i) => i === newId)
        )
      : []
    : [];

  let featuredProducts = categoryProducts
    ? categoryProducts.length
      ? categoryProducts.filter((item) =>
          item.category_ids.every((i) => i !== newId)
        )
      : []
    : [];
  let [showFeaturedProduct, setShowFeaturedProduct] = useState(featuredProducts);



  if (sort.length) {
    filteredProduct = filteredProduct.length
      ? sortFilter(filteredProduct, sort)
      : sortFilter(categoryProducts, sort);
  }

  let filteredNewArrival = filteredProduct.length
    ? filteredProduct.filter((item) =>
        item.category_ids.some((i) => i === newId)
      )
    : [];

  let filteredFeatured = filteredProduct.length
    ? filteredProduct.filter((item) =>
        item.category_ids.every((i) => i !== newId)
      )
    : [];

  const handleSubmit = (data) => {
    window.scrollTo(0, 0);
    setSearch(true)
    categoryProductInit()
    data.pageNum = 1;
    data.post_per_page = "20";

    //console.log(data)
    setData(data)
    setSort(data.sort);
    changePageNumber(1)
    props.fetchCategoryProducts({id: data.category_id, pageNum: 1, post_per_page: "20"},[],true,data)

  };

  const onChangeCategory = (data) => {
    const selectedCategoryIds = [data?.term, brand?.term_id].filter(id => id !== undefined);

    // Filter products that have all selectedCategoryIds in their category_ids array
    const featureProducts = featuredProducts.filter(product =>
        selectedCategoryIds.every(id => product?.category_ids?.includes(id))
    );

    setShowFeaturedProduct(featureProducts)

    console.log('Selected Category:', selectedCategoryIds);
    console.log('Filtered Featured Products:', showFeaturedProduct);
  };


  const handleClear = () => {
    window.scrollTo(0, 0);
    setSearch(false)
    filterInit();
    setSort([]);
    changePageNumber(1)
    setData({
      category_id: null,
      colors: [],
      height: [],
      material: [],
      pageNum: 1,
      post_per_page: '20',
      price: [],
      sort: [],
      width: []
    })
    props.fetchCategoryProducts({id: id, pageNum: 1, post_per_page: "20"},[],false,{})
  };

  let getVariations = categoryProducts
    ? categoryProducts.map((item) => {
        return item.variations;
      })
    : [];

  let variations = getVariations.flat();

  let availableColors = variations.map((item) => {
    let key = item.attributes.attribute_pa_color;
    return key;
  });

  let filteredColors = availableColors.filter(onlyUnique);

  let getColors = categoryProducts
    ? categoryProducts.map((item) => {
        if (item.attributes[0]) {
          if (item.attributes[0].pa_color) {
            let colAttr = item.attributes[0].pa_color;

            if (filteredColors.length) {
              let retCol = filteredColors.map((col) => {
                if (colAttr[col]) {
                  let ret = {
                    name: colAttr[col].name,
                    value: colAttr[col].description,
                    slug: colAttr[col].slug,
                    id: colAttr[col].term_id,
                  };

                  return ret;
                }
              });
              return retCol;
            }
          }
        }
      })
    : [];

  let flatColors = getColors.flat();

  let trueColors = flatColors.filter(Boolean);

  let colors = [];

  for (let i = 0; i < trueColors.length; i++) {
    let found = colors.length
      ? colors.find((item) => item.id === trueColors[i].id)
      : null;
    if (!found) {
      colors.push(trueColors[i]);
    }
  }

  let allMaterials = variations
    ? variations.map((item) => {
        if (item.attributes) {
          if (item.attributes.attribute_pa_materials) {
            let obj = {
              name: item.attributes.attribute_pa_materials,
            };

            return obj;
          }
        }
      })
    : [];

  allMaterials = allMaterials.filter(Boolean);

  let materials = [];

  for (let i = 0; i < allMaterials.length; i++) {
    let found = materials.length
      ? materials.find((item) => item.name === allMaterials[i].name)
      : null;
    if (!found) {
      materials.push(allMaterials[i]);
    }
  }

  let getMaterials = categoryProducts
      ? categoryProducts.map((item) => {
        if (item.attributes[0]) {
          if (item.attributes[0].pa_materials) {
            let colAttr = item.attributes[0].pa_materials;

            //console.log("categoryProducts", colAttr)

            if (materials.length) {
              let retCol = materials.map((col) => {
                if (colAttr[col.name]) {
                  let ret = {
                    name: colAttr[col.name].name,
                    value: colAttr[col.name].description,
                    slug: colAttr[col.name].slug,
                    id: colAttr[col.name].term_id,
                  };

                  return ret;
                }
              });
              return retCol;
            }
          }
        }
      })
      : [];

  let flatMaterials = getMaterials.flat();

  let trueMaterials = flatMaterials.filter(Boolean);

  let finalMaterials = []

  for (let i = 0; i < trueMaterials.length; i++) {
    let found = finalMaterials.length
        ? finalMaterials.find((item) => item.id === trueMaterials[i].id)
        : null;
    if (!found) {
      finalMaterials.push(trueMaterials[i]);
    }
  }

  //console.log("finalMaterials", finalMaterials)

  let handleClose = () => {
    setShowPopup(false);
  };


  const [play, setPlay] = useState(false);
  useEffect(() => {
    const buttonClick = document.querySelector('#play_video');

    // Function to trigger button click
    const handleButtonClick = () => {
      if (buttonClick) {
        setTimeout(() => buttonClick.click(), 500); // Delay by 500ms before clicking
      }
    };

    // Trigger click if page is fully loaded
    if (document.readyState === 'complete') {
      handleButtonClick();
    } else {
      window.addEventListener('load', handleButtonClick);
    }

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('load', handleButtonClick);
    };
  }, []);
  const video = document.getElementById('bgVideo');

  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const video = document.getElementById('bgVideo');

    if (video) {
      // Try to play the video with sound initially
      video.muted = false;
      video.play()
          .then(() => {
            console.log('Video is playing with sound');
            setIsVideoStarted(true); // Video successfully played with sound
          })
          .catch(() => {
            console.log('Autoplay with sound blocked, attempting muted autoplay');

            // Attempt to play the video muted
            video.muted = true;
            video.play()
                .then(() => {
                  console.log('Video is playing muted');
                  setShowOverlay(true); // Show overlay to prompt user to enable sound
                })
                .catch(() => {
                  console.log('Autoplay blocked even when muted');
                });
          });
    }
  }, []);

  // Function to start video with sound on user interaction
  const startVideoWithSound = () => {
    const video = document.getElementById('bgVideo');

    if (video) {
      video.muted = false; // Unmute the video
      video.play()
          .then(() => {
            console.log('Video is now playing with sound');
            setIsVideoStarted(true); // Hide the overlay
            setShowOverlay(false);
          })
          .catch(() => {
            console.log('Play with sound failed');
          });
    }
  };
  const filteredCategories = categories?.filter((category) => category?.parent === 67);
  let history = useHistory();


  console.log(filteredCategories, 'ok category')



  return (
    <StyledAllProduct>
      <Container className="Banner-view">
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                marginTop: 90,
                alignItems: "center",
              }}
            >
              <div className="text-breadcrumb">Category</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">Brands</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">
                {ReactHtmlParser(brandName)}
              </div>
            </div>
            <Divider style={{ marginTop: 6, marginBottom: 30 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            {/*<div className="subTitle">Brands</div>*/}
          </Col>
        </Row>
        <Row className={'custom-position'}>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                zIndex: '1',
                position:"relative",
                top:'90px',
                left:'30px'
              }}
            >
              <div className="top-title">
                <img src={logo} alt="" />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="column-cl">
            <Container>
              <div className="client-single">
                <div className="client-single__inner">

                  {
                    brand?.video ?
                        <>
                          <video
                              id="bgVideo"
                              autoPlay
                              loop
                              playsInline
                              muted
                              poster="https://api.shantalifestyle.com/wp-content/uploads/2024/11/Screenshot-2024-11-13-122305.png"
                          >
                            <source src="https://api.shantalifestyle.com/wp-content/uploads/2024/11/create_your_masterpiece_with_shanta_lifestyle-720p.mp4" type="video/mp4"/>
                          </video>

                          {/* Show overlay button if video hasn't started with sound */}
                          {showOverlay && !isVideoStarted && (
                              <div className={'video_overlay'}
                                   onClick={startVideoWithSound}
                                   style={{
                                     position: 'absolute',
                                     top: 0,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     display: 'flex',
                                     alignItems: 'center',
                                     justifyContent: 'center',
                                     backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                     color: '#fff',
                                     fontSize: '20px',
                                     cursor: 'pointer',
                                     zIndex: 10
                                   }}
                              >
                                No sound? Your browser has some restrictions.<br/>
                                Please click here to play with sound.
                              </div>
                          )}
                        </>
                        :
                        <img src={postImage ? postImage : brandInner} alt=""/>
                  }



                </div>
              </div>
            </Container>
          </Col>
        </Row>
        <div
          style={{
            backgroundColor: "rgba(122,121,121,0.1)",
            height: 1,
            width: "100%",
            marginTop: 40,
            marginBottom: 40,
          }}
        />

        <Text
          text={branDec}
          fontFamily={"Renner,sans-serif"}
          fontSize={14}
          color={"#313132"}
        />
        <Text

          fontSize={14}
          fontFamily={"Poppins"}
          fontWeight={"normal"}
          margin={"30px 0 0 0"}
          text={ReactHtmlParser(postContent)}
        />
      </Container>
      <MobileFilter
        subName={ReactHtmlParser(brandName)}
        id={id}
        brandFilter={true}
        brandList={filteredCategories}
        handleFilters={handleSubmit}
        handleClear={handleClear}
        materials={finalMaterials}
        colors={colors}
      />
      <Container className="body-view">
        <div
          style={{
            backgroundColor: "rgba(122,121,121,0.1)",
            height: 1,
            width: "100%",
            marginTop: 40,
          }}
        />
        <Row>
          <Col xs={3} className="filter-view">
            <AdvanceFilter
              id={id}
              brandFilter={true}
              brandList={filteredCategories}
              handleFilters={handleSubmit}
              handleClear={handleClear}
              materials={finalMaterials}
              colors={colors}
              onChangeCategory={onChangeCategory}
            />
          </Col>
          <Col xs={9} className="column-cl">
            {
              categoryProducts && categoryProducts.length > 0 ?
                  <Container>
                    {filteredProduct.length ? (
                        <>
                          {filteredNewArrival.length ? (
                              <>
                                <AvailableProduct
                                    dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                    featuredProducts={filteredNewArrival}
                                    title={
                                        "Filtered Results of " + ReactHtmlParser(brandName)
                                    }
                                    subTitle={"Pre Book Now"}
                                    setShowPopup={setShowPopup}
                                    catId={catId}
                                    pageNumber={pageNumber}
                                    isSearch={isSearch}
                                    data={data}
                                    changePageNumber={changePageNumber}
                                    fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                    categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                                />
                                <Divider style={{ marginTop: 30, marginBottom: 30 }} />
                              </>
                          ) : (
                              ""
                          )}
                          {filteredFeatured.length ? (
                              <AvailableProduct
                                  dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                  featuredProducts={filteredFeatured}
                                  title={
                                      "Filtered Results of " + ReactHtmlParser(brandName)
                                  }
                                  catId={catId}
                                  isSearch={isSearch}
                                  data={data}
                                  pageNumber={pageNumber}
                                  changePageNumber={changePageNumber}
                                  fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                  //subTitle={"Best Sellers"}
                                  categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                              />
                          ) : (
                              ""
                          )}
                        </>
                    ) : (
                        <>
                          {newArrivalList.length ? (
                              <>
                                <AvailableProduct
                                    dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                    featuredProducts={newArrivalList}
                                    title={ReactHtmlParser(brandName)}
                                    subTitle={"Pre Book Now"}
                                    setShowPopup={setShowPopup}
                                    catId={catId}
                                    isSearch={isSearch}
                                    data={data}
                                    pageNumber={pageNumber}
                                    changePageNumber={changePageNumber}
                                    fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                    categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                                />
                                <Divider style={{ marginTop: 30, marginBottom: 30 }} />
                              </>
                          ) : (
                              ""
                          )}


                          {


                            showFeaturedProduct.length ? (
                                <AvailableProduct
                                    dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                    featuredProducts={showFeaturedProduct}
                                    title={ReactHtmlParser(brandName)}
                                    catId={catId}
                                    isSearch={isSearch}
                                    data={data}
                                    pageNumber={pageNumber}
                                    changePageNumber={changePageNumber}
                                    fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                    //subTitle={"Best Sellers"}
                                    categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                                />
                            ) : <h5 style={{marginTop: 20}}>
                              No product found.
                            </h5>

                          }


                        </>
                    )}
                  </Container>
                  :
                  <h5 style={{marginTop: 20}}>
                    No product found.
                  </h5>
            }

          </Col>
        </Row>
        <Snackbar open={showPopup}
                  autoHideDuration={6000}
                  anchorOrigin={{vertical, horizontal}}
                  key={vertical + horizontal}
                  onClose={handleClose}>
          <Alert onClose={handleClose} sx={{width: '100%'}} color="#ffffff"
                 style={{backgroundColor: '#313132', color: '#ffffff', fontWeight: 450, maxWidth: 500, textAlign: 'left'}}>
            {submit?.message}
          </Alert>
        </Snackbar>
      </Container>
    </StyledAllProduct>
  );
};

const StyledAllProduct = styled.div`
  .container {
    max-width: 95%;
  }

  .custom-position {
    position: absolute;
    top: 50px;

    @media (max-width: 767px) {

    }
  }

  .Banner-view {
    display: block;
  }

  .text-filter {
    font-weight: 600;
    font-size: 14px;
    color: #313132;
    text-decoration: underline;
    font-family: "Poppins", sans-serif;
  }

  .text-filter-menu {
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 8px 16px 8px 16px;
    background-color: #d8d8d8;
    border-radius: 25px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 12px;
  }

  .filter-mobile-view {
    display: none;
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .body-view {
    display: block;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 24px;
    color: rgb(156, 148, 52);
  }

  .top-title {
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 30px;
    color: rgb(156, 148, 52);
  }

  .top-title-text {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner,sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .filter-view {
    display: block;
  }

  .column-cl {
    position: relative;
    margin: 0 0 40px;
    overflow: hidden;
    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: "Poppins", sans-serif;
      padding: 3px 8px 3px 8px;
    }

    &:hover {
      border: 1px solid #9e6c51;
      background-color: #9e6c51;

      .load-button {
        color: #ffffff;
      }
    }
  }

  .client-single {
    &__inner {
      padding-top: 60%;
      border: 0 solid #707070;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: calc(100% - 30px) !important;
        height: 100% !important;
        margin: 0;
      }

      .video_overlay {
        height: 100% !important;
        width: 100% !important;
        z-index: unset !important;
        margin: 0 auto !important;
        text-align: center;
      }

      img {
        height: 100% !important;
      }

      img, video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        object-fit: cover;
        //height: 100% !important;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 991px) {
    .loader {
      margin-top: 30%;
    }

    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .Banner-view {
      display: none;
    }

    .filter-mobile-view {
      display: block;
    }

    .filter-view {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .filter-mobile-view {
      display: block;
    }

    .body-view {
      display: block;
    }

    .filter-view {
      display: none;
    }

    .MuiLinearProgress-root {
      display: none;
    }

    .texts {
      display: none;

      p {
        font-size: 10px;
      }
    }

    .loader {
      margin-top: 45%;
    }

    .read-more {
      background-color: #fff;
      border-radius: 50px;
      border: 1px solid #313132;
      cursor: pointer;
      text-align: center;
      width: 100%;

      .load-button {
        color: #313132;
        font-size: 10px;
        font-weight: normal;
        font-family: "Poppins", sans-serif;
        padding: 8px 16px 8px 16px;
      }
    }
  }
`;


export default AllProduct;
