import React, {Component} from "react";
import '../../App.css';
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import BannerSlider from "./components/BannerSlider";
import FeatureProduct from "./components/FeatureProduct";
import ShopByBrand from "./components/ShopByBrand";
import ShopByRoom from "./components/ShopByRoom";
import {connect} from 'react-redux'
import styled from 'styled-components'
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  fetchBrand,
  fetchCategoryData,
  fetchHomeBannerSlider,
  fetchProductData,
  getAllPosts,
  getBodListData,getScopeOfWork

} from "../../redux/actions/home";
import ShopByScope from "./components/ShopByScope";

class HomeIndex extends Component {
  componentDidMount () {
    window.scrollTo (0, 0);
    this.props.fetchHomeBannerSlider();
    // //console.log('component did mount called');
    this.props.fetchBrand()
    this.props.getBodList();
    this.props.getScope();
    // if (this.props.allProducts) {
    //   if (!this.props.allProducts.length) {
    //     this.props.fetchProductData();
    //   }
    // }
    if ( this.props.posts) {
      if (this.props.posts.length < 1) {
        this.props.getAllPosts();
      }
    }
  }

  render () {
    let {bannerData, loading} = this.props

    let len = bannerData ? bannerData.length : 0
    console.log( this.props, 'ok')

    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <StyledIndex data={len}>
          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
           <>
             <BannerSlider bannerData={bannerData}/>
             <ShopByScope/>

             <ShopByBrand/>
             <ShopByRoom/>

             {
               this.props.featuredProducts &&
               <FeatureProduct/>
             }
           </>
          )}
        </StyledIndex>
      </motion.div>
    );
  }
}

const StyledIndex = styled.div`
  height: ${(props) => (props.data ? "auto" : "100vh")};

  .loader {
    margin-top: 25%;
    text-align: center;

    @media(max-width: 991px){
      margin-top: 40%;
    }
  }
`

function mapStateToProps(state) {
  return {
    bannerData: state.homeReducer.bannerData,
    loading: state.homeReducer.bannerLoading,
    // allProducts: state.homeReducer.allProducts,
    // posts: state.homeReducer.posts,
    featuredProducts: state.homeReducer.featuredProducts,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchHomeBannerSlider: () => dispatch(fetchHomeBannerSlider()),
    fetchBrand: () => dispatch(fetchBrand()),
    fetchProductData: () => dispatch(fetchProductData()),
    getAllPosts: () => dispatch(getAllPosts()),
    getBodList: () => dispatch(getBodListData()),
    getScope: () => dispatch(getScopeOfWork())
    // fetchCategoryData: () => dispatch(fetchCategoryData()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeIndex);
